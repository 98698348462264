<template>
    <div class='container'>
        <object v-bind:data="datasourceURL" width="100%" height="100%" style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;left:0px;right:0px;bottom:0px">
                    <embed v-bind:src="datasourceURL" width="100%" height="100%">{{$t('datasources.embed_error')}}
                </object>

    </div>
</template>

<script>
import Vue from 'vue'
import ViewService from 'src/services/view'
const config = require('config');
const baseUrl = config.apiUrl.split('.')[1];
const studioSubdomain = config.studioSubdomain;

export default {

    data() {
        return {
            datasourceURL: ''
        }
    },
    methods: {},
    created() {
        var datasourceName = this.$route.params.datasourceName;
        if (this.$route.params.projectName == 'snest') {
            this.datasourceURL = `https://${studioSubdomain}.${baseUrl}.com/#/YoshidaLeoy/projects/EasyNest/SimpleNest4#${datasourceName}#@3`;
        }
        if (this.$route.params.projectName == 'nest') {
            this.datasourceURL = `https://${studioSubdomain}.${baseUrl}.com/#/YoshidaLeoy/projects/EasyNest/NestCarousel3#${datasourceName}#@167`;
        }
        if (this.$route.params.projectName == 'pivottable') {
            this.datasourceURL = `https://${studioSubdomain}.${baseUrl}.com/#/YoshidaLeoy/projects/EasyNest/Dashboards/PivotTable#${datasourceName}#@6`;
        }
        if (this.$route.params.projectName == 'tableexplorer') {
            this.datasourceURL = `https://${studioSubdomain}.${baseUrl}.com/#/YoshidaLeoy/projects/EasyNest/Dashboards/TableExplorer#${datasourceName}#@1`;
        }
        if (this.$route.params.projectName == 'linegraph') {
            this.datasourceURL = `https://${studioSubdomain}.${baseUrl}.com/#/YoshidaLeoy/projects/EasyNest/Dashboards/SimplePlotly#${datasourceName}#@18`;
        }
        if (this.$route.params.projectName == 'treemap') {
            this.datasourceURL = `https://${studioSubdomain}.${baseUrl}.com/#/victor/EZNest_TreeMap#${datasourceName}#@31`;
        }


    }
}
</script>

<style>

</style>
