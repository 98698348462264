module.exports = {
  apiUrl: 'https://api-ocp.lichen.com',
  bucket: 'home.lichen.com',
  logout: 'lichen.com/logout',
  sentry: {
    dsn: 'https://e89017876a1d4798a67eb0d971289c49@sentry.io/1861374',
    env: 'PRODUCTION'
  },
  studioSubdomain: 'studio-ocp'
}
