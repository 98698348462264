<template>
  <div id="objectContainer" class='container'>
  </div>
</template>

<script>
  import Vue from 'vue'
  import ViewService from 'src/services/view'
  const config = require('config');
  const studioSubdomain = config.studioSubdomain;

  export default {
    mounted() {
      let objectEl = document.createElement('object');
      objectEl.setAttribute('type', 'text/html');
      objectEl.setAttribute('width', '100%');
      objectEl.setAttribute('height', '100%');
      objectEl.setAttribute('style', 'overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;left:0px;right:0px;bottom:0px');

      let embedEl = document.createElement('embed');
      embedEl.setAttribute('width', '100%');
      embedEl.setAttribute('height', '100%');

      let errorEl = document.createTextNode("Error: Embedded data could not be displayed.");

      objectEl.appendChild(embedEl);
      objectEl.appendChild(errorEl);

      let divEl = document.getElementById("objectContainer");
      divEl.appendChild(objectEl);

      let viewName = this.$route.params.viewName;
      ViewService.retrieveView(viewName)
        .then(result => {
          if (result && result.projectUrl) {
            document.title = result.name;

            let viewUrl;

            if (result.projectUrl.startsWith('#')) {
              const baseUrl = require('config').apiUrl.split('.')[1];
              viewUrl = `https://${studioSubdomain}.${baseUrl}.com/${result.projectUrl}`;

            } else {
              viewUrl = result.projectUrl;
            }

            objectEl.setAttribute('data', viewUrl);
            embedEl.setAttribute('src', viewUrl);
          }
        })
        .catch(err => console.log(err));
    }
  }
</script>

<style>
</style>
