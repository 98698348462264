<template>
    <div class='container'>
        <object v-if="datasetURL" v-bind:data="datasetURL" width="100%" height="100%" style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;left:0px;right:0px;bottom:0px">
            <embed v-bind:src="datasetURL" width="100%" height="100%">{{$t('datasources.embed_error')}}
        </object>
    </div>
</template>

<script>
import Vue from 'vue'
import ViewService from 'src/services/view'
import DatasetService from 'src/services/dataset'
const config = require('config');
const baseUrl = config.apiUrl.split('.')[1];
const studioSubdomain = config.studioSubdomain;

export default {

    data() {
        return {
            datasetURL: ''
        }
    },
    async mounted() {
        var datasetPublicId = this.$route.params.datasetPublicId;
        var actionHash = btoa(`[**dataset_id**->${datasetPublicId}]`);
        var projectName = this.$route.params.projectName.toLowerCase();
        var conversion = "#"+btoa("[**dataset_id**->"+datasetPublicId+"]");

        // Get the dataset info, including the list of SDAs, frm the DB
        let datasetDetails = await DatasetService.getDatasetByPublicId( datasetPublicId );
        let si = datasetDetails.statisticalInfo;

        if (si.standardDataApps) {
            // Find the requested SDA among all the available ones for this dataset
            var projectMatch = si.standardDataApps.find( function(app){
                return app.id.toLowerCase() == projectName;
            });

            // If (when) found, use its info to ...
            if ( projectMatch ) {
                // Change the page title
                $("title").text( projectMatch.name + " - " + datasetDetails.name );

                // and Set the datasetURL so that it gets loaded in the iframe
                this.datasetURL = `https://${studioSubdomain}.${baseUrl}.com/layout.html${projectMatch.project}#@${projectMatch.layoutId + conversion}`;
            }
        }
    }
}
</script>

<style>
</style>
